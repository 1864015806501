import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { ALL_PHOTOGRAPHERS } from '../../../queries';

import HomeComponent from './HomeComponent';

import './Style.css';

const LendingPath = () => {
  const { loading, error, data } = useQuery(ALL_PHOTOGRAPHERS, {
    fetchPolicy: 'cache-first',
  });
  const [homeComponentSize, setHomeComponentSize] = useState(10250);
  let viewportWidth = window.innerWidth;
  let allHomeComponentSize = [];

  if (viewportWidth > 1100) {
    allHomeComponentSize = [
      4100,
      4400,
      4700,
      5000,
      5400,
      5700,
      6000,
      6200,
      6500,
      6800,
      7100,
      7400,
      7700,
      8100,
      8400,
      8700,
      9000,
      9200,
      9500,
      9800,
      10000,
      10250,
    ];
  } else if (viewportWidth <= 1100 && viewportWidth > 950) {
    allHomeComponentSize = [
      3300,
      3650,
      3900,
      4100,
      4400,
      4700,
      5000,
      5200,
      5400,
      5700,
      5900,
      6000,
      6300,
      6600,
      6900,
      7100,
      7300,
      7500,
      7800,
      8000,
      8300,
      8500,
    ];
  } else if (viewportWidth <= 950 && viewportWidth > 710) {
    allHomeComponentSize = [
      3400,
      3600,
      3800,
      4000,
      4200,
      4400,
      4600,
      4800,
      4900,
      5100,
      5200,
      5400,
      5600,
      5800,
      6000,
      6200,
      6400,
      6600,
      6800,
      7000,
      7200,
      7400,
    ];
  } else if (viewportWidth <= 710 && viewportWidth > 540) {
    allHomeComponentSize = [
      3300,
      3500,
      3700,
      3900,
      4000,
      4100,
      4300,
      4600,
      4700,
      4800,
      4900,
      5150,
      5300,
      5400,
      5550,
      5700,
      5900,
      6000,
      6100,
      6200,
      6400,
      6600,
    ];
  } else if (viewportWidth <= 540 && viewportWidth > 420) {
    allHomeComponentSize = [
      2850,
      2950,
      3200,
      3300,
      3450,
      3600,
      3750,
      3900,
      4000,
      4200,
      4400,
      4500,
      4600,
      4900,
      4900,
      5200,
      5200,
      5400,
      5400,
      5600,
      5600,
      5900,
    ];
  } else if (viewportWidth <= 420) {
    allHomeComponentSize = [
      2600,
      2850,
      3000,
      3200,
      3300,
      3400,
      3500,
      3600,
      3700,
      3900,
      4000,
      4100,
      4300,
      4400,
      4500,
      4600,
      4900,
      4900,
      5000,
      5100,
      5200,
      5300,
    ];
  }

  useEffect(() => {
    const getHomeComponentSize = (lengthData, arrSize) => {
      let index = lengthData - 9;
      setHomeComponentSize(arrSize[index]);
    };
    getHomeComponentSize(data.allPhotographers.length, allHomeComponentSize);
  }, [data.allPhotographers.length]);

  if (loading) return <div className="--loading">Loading</div>;
  if (error) return <div className="--error">An Error has Ocurred</div>;

  return (
    <div
      className="home-component_size"
      style={{ height: `${homeComponentSize}px` }}
    >
      <HomeComponent frontdata={data} />
    </div>
  );
};

export default LendingPath;
