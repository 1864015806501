import React from 'react';
import AboutImg from './about.png';
import Geo from './Geo.png';
import Instagram from './Instagram.png';
import HFLogo from './HF-logo.png';
import { usePhotographers } from '../../hooks/usePhotographers';
import { Link } from 'react-router-dom';
import './styles.scss';
import { useRouteMatch } from 'react-router-dom';

const About = (props) => {
  const router = useRouteMatch();

  if (router.path === '/About') {
    document.body.style.width = '';
    document.body.style.height = '';
    document.body.style.overflowX = '';
    document.body.style.overflowY = '';
  }

  window.addEventListener('scroll', function () {
    if (window.scrollY > 600) {
      if (router.path === '/About') {
        document.body.style.overflowY = '';
      }
    }
  });

  return usePhotographers(({ allPhotographers }) => {
    let allPhotographersSort = allPhotographers.slice();
    allPhotographersSort.sort((a, b) => (a.order > b.order ? 1 : -1));

    return (
      <div className="About">
        <div className="--header">
          <h1>THE COMPANY WE KEEP</h1>
        </div>
        <div className="--company-info">
          <img src={AboutImg} alt="about" className="--aboutImage" />
          <div>
            <p>
              Since the moment our company was born, our name became more
              than a brand, it became our way of business. We've always 
              been one big, silly, dedicated, supporting Happy Family.
            </p>
            <p>
              And this virtual trip is an opportunity for us to expand our 
              photographic family tree and share the perspectives of parenting
              through pictures from across the country. It's a slice of life
              from across the nation, the things we share and those we don't.
              From wondrously cranky to the sibling love, it's a tapestry of
              family life.
            </p>
            <p>
              If you would like to join the road trip, please email us at <a className="--link" href="mailto:roadtrip@happyfamilybrands.com">roadtrip@happyfamilybrands.com</a>.
            </p>
            <img src={HFLogo} alt="HFLogo" className="--aboutLogo" />
          </div>
        </div>
        <div className="--photgrapher-info">
          <h3>
            Featured <br /> Photographers
          </h3>
          <ul className="--photographerList">
            {allPhotographersSort.map((item) => {
              if (!item.visible) return null;
              if (!item.featured) return null;
              return (
                <li key={item.id}>
                  <Link to={`/set/${item.id}`}>{item.fullName}</Link>
                  <a href={`/set/${item.id}`}>
                    <img src={Geo} alt="Geo" className="--icon" />
                  </a>
                  <a href={item.instagram} target="_blank">
                    <img src={Instagram} alt="Instagram" className="--icon" />
                  </a>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    );
  });
};

export default About;
