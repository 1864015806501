import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { getAdminPath } from './utilities';
import {
  ApolloProvider,
  ApolloClient,
  InMemoryCache,
  HttpLink,
} from '@apollo/client';
import { resolvers, typeDefs } from './resolvers';
import './fonts/Pixel Colours - Rational Mood.otf';

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: new HttpLink({
    uri: getAdminPath(`/admin/api/`),
  }),
  typeDefs,
  resolvers,
});

ReactDOM.render(
  <React.StrictMode>
    <div>
      <ApolloProvider client={client}>
        <App />
      </ApolloProvider>
    </div>
  </React.StrictMode>,
  document.getElementById('root')
);
serviceWorker.register();
