import { gql } from '@apollo/client';

export const ALL_PHOTOGRAPHERS = gql`
  query {
    allPhotographers(where: { visible: true }) {
      order
      fullName
      id
      location
      visible
      featured
      familyName
      instagram
      familyNameGraphic {
        filename
        path
        publicUrl
      }
      photosets {
        description
        label
        quotes {
          id
          copy
          graphics {
            id
            file {
              publicUrl
            }
          }
        }
        graphics {
          id
          file {
            id
            publicUrl
          }
        }
        photos {
          caption
          file {
            id
            publicUrl
          }
        }
      }
    }
  }
`;

export const ALL_PHOTOS = gql`
  query photosandtags {
    allPhotos(where: { visible: true }) {
      id
      label
      tags {
        id
        type
      }
      visible
      file {
        id
        filename
        path
        publicUrl
      }
      photoset {
        label
        photographers {
          fullName
          location
          id
          visible
        }
      }
    }

    allTags {
      type
      id
      _label_
      icon {
        id
        publicUrl
      }
      photos {
        _label_
        file {
          filename
          id
          path
          publicUrl
          originalFilename
        }
        visible
        label
        id
      }
    }
  }
`;
