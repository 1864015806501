export const getAdminPath = (_path) => {
  // if (window.location.hostname === "localhost") {
  //   return `http://188.166.166.183:3200${_path}`;
  // } else {
  return `https://hfrt-admin.herokuapp.com${_path}`;
  // }
};

export const lerp = (v0, v1, t) => {
  return v0 * (1 - t) + v1 * t;
};

export const randInt = (f, c) => {
  return Math.floor(randRange(f, c));
};

export const randRange = (f, c) => {
  return f + Math.random() * (c - f);
};

export function resizeWidthOnly(a, b) {
  var c = [window.innerWidth];
  return (
    (window.onresize = function () {
      var d = window.innerWidth,
        e = c.length;
      c.push(d);
      if (c[e] !== c[e - 1]) {
        clearTimeout(b);
        b = setTimeout(a, 50);
      }
    }),
    a
  );
}
