import Animal1 from '../assets/_animals/anim_2.png';
import Animal2 from '../assets/_animals/anim_3.png';
import Animal3 from '../assets/_animals/anim_4.png';
import Animal4 from '../assets/_animals/anim_5.png';
import Animal5 from '../assets/_animals/anim_6.png';

import Geo1 from '../assets/_geological/geo_6.png';
import Geo2 from '../assets/_geological/geo_7.png';
import Geo3 from '../assets/_geological/geo_8.png';
import Geo4 from '../assets/_geological/geo_9.png';

import Misc1 from '../assets/_misc/misc_1.png';
import Misc2 from '../assets/_misc/misc_2.png';
import Misc3 from '../assets/_misc/misc_3.png';
import Misc4 from '../assets/_misc/misc_4.png';
import Misc5 from '../assets/_misc/misc_5.png';

import Plant1 from '../assets/_plants/plant_1.png';
import Plant2 from '../assets/_plants/plant_2.png';
import Plant3 from '../assets/_plants/plant_3.png';
import Plant4 from '../assets/_plants/plant_4.png';
import Plant5 from '../assets/_plants/plant_5.png';

import Anim1 from './images/anim_1.png';
import Anim6 from './images/anim_6.png';
import Cloud from './images/cloud_1.png';
import Tree1 from './images/tree_1.png';
import Tree3 from './images/tree_3.png';
import Treeside from './images/Treesside.png';

export const Images = () => {
  return [
    Geo1,
    Geo2,
    Geo3,
    Geo4,
    Animal1,
    Animal2,
    Animal3,
    Animal4,
    Animal5,
    Misc1,
    Misc2,
    Misc3,
    Misc4,
    Misc5,
    Plant1,
    Plant2,
    Plant3,
    Plant4,
    Plant5,
  ];
};

export const getRandomIllustrations = () => {
  return [
    Anim1,
    Geo1,
    Anim1,
    Anim6,
    Geo2,
    Tree1,
    Animal2,
    Misc1,
    Tree3,
    Cloud,
    Misc2,
    Geo3,
    Misc3,
    Treeside,
  ];
};
