import React, { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import './styles.scss';
import SimpleReactLightbox from 'simple-react-lightbox-pro';
import { SRLWrapper } from 'simple-react-lightbox-pro';
import { useRouteMatch } from 'react-router-dom';
import { LazyImage } from "react-lazy-images";
import { allTags, allPhotos } from './archiveData.json';

const Archive = (props) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const filter = props.match.params.filter;
  const router = useRouteMatch();

  if (router.path === '/Archive') {
    document.body.style.width = '';
    document.body.style.height = '';
    document.body.style.overflowX = '';
    document.body.style.overflowY = '';
  }

  window.addEventListener('scroll', function () {
    if (window.scrollY > 600) {
      if (router.path === '/Archive') {
        document.body.style.overflowY = '';
      }
    }
  });

  const captions = [];

  const outputPhoto = (photo) => {
    if (photo.file) {
      return (
        <li key={photo.id}>
          <LazyImage
            src={photo.file.publicUrl}
            placeholder={({ ref }) => (
              <img style={{visibility: 'hidden'}} height='450' width='450' ref={ref} src={''}/>
            )}
            actual={({ imageProps }) => <img loading="lazy"  {...imageProps} />}
          />
        </li>
      );
    }
  };

  const showPhotosByFilter = (filter) => {
    if (!filter && allPhotos && allPhotos.length > 0) {
      return allPhotos.map((p, index) => {
        console.log(p)
        const link = p.photoset[0].photographers.length ? `/Set/${p.photoset[0].photographers[0].id}` : '/';

        captions.push({
          id: index,
          caption: (
            <a href={link}>
              {p.photoset[0].label}
            </a>
          ),
        }); 

        return outputPhoto(p);
      });
    }

    const filteredTags = allTags.filter((item) => {
      if (item.type === filter) {
        return item;
      }
      return null;
    })[0];
    
    if (
      filteredTags &&
      filteredTags.photos &&
      filteredTags.photos.length > 0
    ) {
      return filteredTags.photos.map((p, index) => {
        captions.push({
          id: index,
          caption: (
            <a href={`/Set/${p.photoset[0].photographers[0].id}`}>
              {p.photoset[0].label}
            </a>
          ),
        }); 

        return outputPhoto(p);
      });
    }

    return <div>no photos</div>;
  };

  return (
    <div className="Archive normal-section">
      <div className="--header">
        <h1>THE GALLERY</h1>
      </div>
      <nav className="--filterNav">
        <div className="--filterNav-Container">
          {allTags.map((tag) => {
            return (
              <div className={`--filter-inner --${tag.type}`} key={tag.id}>
                <NavLink
                  activeClassName="__selected"
                  to={`/Archive/${tag.type}`}
                >
                  <div className="--image-container">
                    <div
                      className="--icon"
                      style={{
                        backgroundImage: `url(${tag.icon.publicUrl})`,
                      }}
                    ></div>
                  </div>
                  <div className="--text">{tag.type}</div>
                </NavLink>
                <div className="tab-close">
                  <NavLink to={`/Archive`}>&times;</NavLink>
                </div>
              </div>
            );
          })}
        </div>
      </nav>
      <SimpleReactLightbox>
        <SRLWrapper customCaptions={captions} options={{settings: {disablePanzoom: true}}}>
          <ul className="--filteredPhotoContainer mediagrid">
            {showPhotosByFilter(filter)}
          </ul>
        </SRLWrapper>
      </SimpleReactLightbox>
    </div>
  );
};

export default Archive;
