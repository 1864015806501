import React from 'react';
import { useQuery } from '@apollo/client';
import { ALL_PHOTOGRAPHERS } from '../../queries';
import Photos from './photos';
import '../LandingPath/component/Style.css';

const Photoset = (props) => {
  const { loading, error, data } = useQuery(ALL_PHOTOGRAPHERS, {
    fetchPolicy: 'cache-first',
  });

  return (
    <>
      <Photos setdata={data} />
    </>
  );
};

export default Photoset;
