import { useQuery } from '@apollo/client';
import { ALL_PHOTOGRAPHERS } from '../queries';
import useLoading from './useLoading';
export const usePhotographers = (output) => {
  return useLoading(
    useQuery(ALL_PHOTOGRAPHERS, {
      fetchPolicy: 'cache-first',
    }),
    output
  );
};
