import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import SideNav from './features/SideNav';
import Archive from './features/Archive';
import About from './features/About';
import Photoset from './features/Photoset';
import { usePhotographers } from './hooks/usePhotographers';
import './App.scss';

import LendingPath from './features/LandingPath/component/LendingPath';

function App() {
  return usePhotographers(() => {
    return (
      <>
        <div className="App">
          <Router>
            <SideNav />
            <Switch>
              <Route exact path="/">
                <LendingPath />
              </Route>
              <div className="--content">
                <Route path="/Set/:id" component={Photoset} />
                <Route
                  path="/Archive"
                  render={({ match: { url } }) => (
                    <>
                      <Route path={`${url}/`} component={Archive} exact />
                      <Route path={`${url}/:filter`} component={Archive} />
                    </>
                  )}
                />
                <Route path="/About" component={About} />
              </div>
            </Switch>
          </Router>
        </div>

        <div className="orientation-msg">
          <p>Please use this site in the portrait mode</p>
        </div>
      </>
    );
  });
}

export default App;
