import React, { useEffect } from 'react';
import './styles.css';
import '../LandingPath/component/Style.css';
import { useParams, useHistory, useRouteMatch, Link } from 'react-router-dom';
import { getRandomIllustrations } from '../LandingPath/component/images';
import Clouds from '../LandingPath/component/images/cloud_1.png';
import Trees from '../LandingPath/component/images/tree_1.png';
import Birds from '../LandingPath/component/images/anim_1.png';
import JoinTheTrip from '../LandingPath/assets/join_svg.svg';
import Instagram from '../About/Instagram.png';
import SimpleReactLightbox from 'simple-react-lightbox-pro';
import { SRLWrapper } from 'simple-react-lightbox-pro';

const Photos = (props) => {
  let history = useHistory();
  let routeerr = useRouteMatch();
  if (routeerr.path === '/Set/:id') {
    document.body.style.width = '';
    document.body.style.height = '';
    document.body.style.overflowX = '';
    document.body.style.overflowY = '';
  }

  window.addEventListener('scroll', function () {
    if (window.scrollY > 600) {
      if (routeerr.path === '/Set/:id') {
        document.body.style.overflowY = '';
      }
    }
  });
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  let totalslidex;

  let paramphoto = useParams();
  const illustrations = getRandomIllustrations();
  let allPhotos = props.setdata.allPhotographers.slice();
  allPhotos.sort((a, b) => (a.order > b.order ? 1 : -1));

  let individualFamilyPhotos = allPhotos.find((photo) => {
    return photo.id === paramphoto.id;
  });
  const nextFamilyIndex = allPhotos.findIndex(
    (photo) => photo.id === paramphoto.id
  );

  if (!individualFamilyPhotos.photosets[0]) {
    return <>No Photos Available</>;
  }

  let photoset = individualFamilyPhotos.photosets[0].photos;
  let quotes = individualFamilyPhotos.photosets[0].quotes;
  let allPhotographers = allPhotos.slice();
  const param = window.location.pathname.split('/').pop();
  const currentIndex = allPhotographers.findIndex((item) => item.id === param);

  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    history.push(`/Set/${allPhotographers[currentIndex + 1].id}`);
  };

  const callbacks = {
    onSlideChange: (object) => {
      if (object.action == 'right') {
        if (object.slides.current.caption == 'Illsutration') {
          setTimeout(() => {
            let x = document.getElementsByClassName('SRLNextButton')[0];
            x.click();
          }, 10);
        }
      } else if (object.action == 'left' && object.index > 0) {
        if (object.slides.current.caption == 'Illsutration') {
          setTimeout(() => {
            let xy = document.getElementsByClassName('SRLPrevButton')[1];
            xy.click();
          }, 10);
        }
      }
    },
    onCountSlides: (object) => {
      totalslidex = object.totalslide;
    },
  };

  const createLayout = () => {
    let i = 0;
    let j = 0;
    const div = [];
    const maxAnimation = 22;
    let animationCounter = 0;
    while (i < photoset.length) {
      div.push(
        <div className={i % 2 === 0 ? 'photo-option1' : 'photo-option2'}>
          <ul
            className={
              i % 2 === 0 ? 'photoSetGridOption1' : 'photoSetGridOption2'
            }
          >
            {photoset[i] && (
              <li>
                <img
                  loading={i < 3 ? 'eager' : 'lazy'}
                  src={photoset[i].file.publicUrl}
                  alt={photoset[i].caption}
                />
                {photoset[i + 1] && (
                  <span className={i % 2 === 0 ? 'mid-img' : 'mid-img2'}>
                    <img
                      className="illutration-animation"
                      src={illustrations[Math.floor(Math.random() * 13) + 1]}
                      alt="Illsutration"
                      srl_exclude="true"
                    />
                  </span>
                )}
              </li>
            )}
            {photoset[i + 1] && (
              <li>
                <img
                  loading={i < 3 ? 'eager' : 'lazy'}
                  src={photoset[i + 1].file.publicUrl}
                  alt={photoset[i].caption}
                />
              </li>
            )}
            {photoset[i + 2] && (
              <li>
                <img
                  loading="lazy"
                  src={photoset[i + 2].file.publicUrl}
                  alt={photoset[i].caption}
                />
              </li>
            )}
          </ul>
          <span className={i % 2 === 0 ? 'top-img' : 'top-img2'}>
            <img
              className="illutration-animation"
              src={illustrations[Math.floor(Math.random() * 13) + 1]}
              alt="Illsutration"
              srl_exclude="true"
            />
          </span>
        </div>
      );
      if (quotes[j]) {
        div.push(
          <div
            className={i % 2 === 0 ? 'photoGridContent1' : 'photoGridContent2'}
          >
            <span>{quotes[j].copy}</span>{' '}
            {quotes[j].graphics ? (
              <img
                className="illutration-animation"
                src={quotes[j].graphics.file.publicUrl}
                alt="Illsutration"
                srl_exclude="true"
              />
            ) : (
              ''
            )}
          </div>
        );
        j++;
      }
      i += 3;

      if (animationCounter >= maxAnimation) {
        animationCounter = 0;
      } else {
        animationCounter += 2;
      }
    }
    return div;
  };

  const getInstagramUsername = (link) => {
    return link ? link.split('/').pop() : '';
  };

  return (
    <div className="photoSetGrid">
      <h1>
        We're the {individualFamilyPhotos.fullName} family{' '}
        <span>from {individualFamilyPhotos.location}</span>
      </h1>

      {
        individualFamilyPhotos.instagram && (
          <a className="instagram-link" href={individualFamilyPhotos.instagram} target="_blank">
            <img src={Instagram} alt="Instagram" />
            {`@${getInstagramUsername(individualFamilyPhotos.instagram)}`}
          </a>
        )
      }

      <SimpleReactLightbox>
        <SRLWrapper callbacks={callbacks} options={{settings: {disablePanzoom: true}}}>{createLayout()}</SRLWrapper>
      </SimpleReactLightbox>

      <div className="PhotoSet">
        <div className="row">
          <div className="column">
            <img src={Trees} alt="Snow" />
          </div>
          <div className="column">
            <img src={Birds} alt="Forest" />
          </div>
          <div className="column">
            <img src={Clouds} alt="Mountains" />
          </div>
        </div>

        {allPhotos[nextFamilyIndex + 1] && (
          <div className="row" onClick={scrollTop}>
            <div className="column"></div>
            <div className="column next-step">
              <div className="vertical_line"></div>
              <p className="next_location">Next Location</p>
            </div>
            <div className="column"></div>
          </div>
        )}
        {allPhotos[nextFamilyIndex + 1] && (
          <div className="row" onClick={scrollTop}>
            <div class="footer_text">
              <span className="text_holder">
                &nbsp;Hi, WE'RE THE{' '}
                {allPhotos[nextFamilyIndex + 1]
                  ? allPhotos[nextFamilyIndex + 1].familyName
                  : ''}{' '}
                FROM
              </span>
              <span className="text_holder2">
                <span class="text_holder">
                  &nbsp;
                  {allPhotos[nextFamilyIndex + 1]
                    ? allPhotos[nextFamilyIndex + 1].location
                    : ''}
                  .
                </span>
              </span>
            </div>
          </div>
        )}
        {nextFamilyIndex + 1 == allPhotos.length && (
          <div className="photoset-page_join">
            <Link to={'/About'}>
              <img src={JoinTheTrip} alt='' />
            </Link>
          </div>
        )}
        <div className="bottom-img">
          {allPhotos[nextFamilyIndex + 1] &&
          allPhotos[nextFamilyIndex + 1].photosets.length &&
          allPhotos[nextFamilyIndex + 1].photosets[0].photos[0] ? (
            <img
              src={
                allPhotos[nextFamilyIndex + 1].photosets[0].photos[0].file
                  .publicUrl
              }
            />
          ) : (
            ''
          )}
        </div>
      </div>
    </div>
  );
};

export default Photos;
