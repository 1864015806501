import React, { useState, useEffect } from 'react';
import './SideNav.scss';
import { useQuery } from '@apollo/client';
import {
  Link,
  NavLink,
  useRouteMatch,
  useLocation,
  withRouter,
} from 'react-router-dom';
import { ALL_PHOTOGRAPHERS } from '../../queries';
import FamilyLogo from './HFRT_logo.svg';
import HFLogo from './HF_logo.svg';
import Sidepath from './sidepath';
import newgola from './happycircle.png';

const findWithAttr = (array, attr, value) => {
  for (var i = 0; i < array.length; i++) {
    if (array[i][attr] === value) {
      return i;
    }
  }
  return -1;
};

const SideNav = (props) => {
  const { loading, error, data } = useQuery(ALL_PHOTOGRAPHERS, {
    fetchPolicy: 'cache-first',
  });
  
  const [menuExp, setMenuExp] = useState(false);
  const [burgerClass, setBurgerClass] = useState('');
  const [Logo, setLogo] = useState(FamilyLogo);
  const param = window.location.pathname.split('/').pop();
  const [burgerIconClass, setBurgerIconClass] = useState('');
  const [frstbarCoordination, setFrstbarCoordination] = useState({
    x: 10,
    y: 10,
  });
  const [thrdbarCoordination, setThrdbarCoordination] = useState({
    x: 10,
    y: 30,
  });

  let allPhotographersSort = data.allPhotographers.slice();
  allPhotographersSort.sort((a, b) => (a.order > b.order ? 1 : -1));

  const toggleMenu = (e) => {
    setBurgerClass(menuExp ? '' : 'is-active');
    setBurgerIconClass(menuExp ? '' : 'open');
    setFrstbarCoordination(menuExp ? { x: 10, y: 10 } : { x: 1, y: 4 });
    setThrdbarCoordination(menuExp ? { x: 10, y: 30 } : { x: 4, y: 40 });
    setMenuExp(!menuExp);
  };

  useEffect(() => {
    if (window.location.pathname.search('Set') !== -1) {
      setLogo(Sidepath);
    } else {
      setLogo(FamilyLogo);
    }
    props.history.listen(() => {
      if (window.location.pathname.search('Set') !== -1) {
        setLogo(Sidepath);
      } else {
        setLogo(FamilyLogo);
      }
    });
  }, []);

  const redirectlink = () => {
    window.location.href = '/';
  };

  return (
    <div className={`SideNav --exp-${menuExp}`}>
      <ul className={`--exp-nav --exp-${menuExp}`}>
        <li>
          <NavLink to="/" exact activeClassName="__active" onClick={toggleMenu}>
            <span>Home</span>
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/Archive"
            activeClassName="__active"
            onClick={toggleMenu}
          >
            <span>The Gallery</span>
          </NavLink>
        </li>
        <li>
          <NavLink to="/About" activeClassName="__active" onClick={toggleMenu}>
            {' '}
            <span>About the Project</span>
          </NavLink>
        </li>
      </ul>
      <div className={`--main-container`}>
        <nav className="--navigation-container">
          <button
            className={`--hamburger hamburger hamburger--elastic ${burgerClass}`}
            onClick={toggleMenu}
            type="button"
            aria-label="Menu"
            aria-controls="navigation"
            aria-expanded={menuExp}
          >
            <svg
              id="burgericon"
              className={burgerIconClass}
              width="50"
              height="50"
            >
              <g className="icon">
                <rect
                  className="frstbar"
                  x={frstbarCoordination.x}
                  y={frstbarCoordination.y}
                  width="32"
                  height="4"
                  rx="2"
                  ry="2"
                  fill="black"
                />

                <rect
                  className="scndbar"
                  x="10"
                  y="20"
                  width="32"
                  height="4"
                  rx="2"
                  ry="2"
                  fill="black"
                />
                <rect
                  className="thrdbar"
                  x={thrdbarCoordination.x}
                  y={thrdbarCoordination.y}
                  width="32"
                  height="4"
                  rx="2"
                  ry="2"
                  fill="black"
                />
              </g>
            </svg>
          </button>
        </nav>
        {window.location.pathname.search('Set') !== -1 ? (
          <div className="hfrt-logo">
            <a href="/">HRFT</a>
          </div>
        ) : (
          ''
        )}
        <div className="--large-hfrt-logo">
          {window.location.pathname.search('Set') == -1 ? (
            <img
              style={{ cursor: 'pointer' }}
              onClick={redirectlink}
              src={FamilyLogo}
              alt="logo"
            />
          ) : (
            <Sidepath
              indexVal={findWithAttr(allPhotographersSort, 'id', param)}
              allData={data.allPhotographers.length}
            />
          )}
        </div>
        <div className="--corp-logo">
          <a
            href="https://www.happyfamilyorganics.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span className="--corp-logo-span">by Happy Family</span>
            <img src={newgola} alt="Happy Family" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default withRouter(SideNav);
