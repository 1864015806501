import React from 'react';
const useLoading = ({ loading, error, data }, output) => {
  if (loading) {
    return (
      <div className="--loading">
        <div className="spinner spinner-1"></div>
      </div>
    );
  }

  if (error) {
    return <div className="--error">An Error has Ocurred</div>;
  }

  if (data) {
    return output(data);
  }
};

export default useLoading;
