import React, { useEffect, useRef, useState } from 'react';
import ScrollMagic from 'scrollmagic';
import gsap, { TimelineMax, TweenMax, TweenLite } from 'gsap';
import { ScrollMagicPluginGsap } from 'scrollmagic-plugin-gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import RoadPath from './RoadPath';
import MobileRoadPath from './MobileRoadPath';
import { resizeWidthOnly } from '../../../utilities';

import Clouds from './images/cloud_1.png';
import Trees from './images/tree_1.png';
import Birds from './images/Comp-1.gif';
import GEO1 from './images/geo_1.png';
import TreeSide from './images/Treesside.png';
import Animal1 from './images/anim_6.png';
import Geo3 from './images/geo_3.png';
import Misc2 from './images/misc_2.png';
import Trees1 from './images/tree_3.png';
import Tiger from '../assets/_animals/anim_2.png';
import {
  Agave,
  Aloe,
  Bear,
  BigBirds,
  Butterflies,
  Cacti,
  Cat,
  Corn,
  FirTrees,
  Flowers,
  Glasses,
  Moth,
  Pond,
  Skyscrapers,
  Wolf,
  Deer,
  Canyon,
} from '../assets/third_section_bg/index';

import './adddata.css';
import './secondphase.css';
import './firstphase.css';
import './thirdphase.css';
import './Style.css';
import './bg_style.css';

gsap.registerPlugin(ScrollTrigger);

ScrollMagicPluginGsap(ScrollMagic, TweenMax, TimelineMax);

const TweenComponent = (props) => {
  let triggerRef = useRef(null);
  let triggerOneRef = useRef(null);
  let animationRef = useRef(null);
  let textGradientRef = useRef(null);
  let cloudOneRef = useRef(null);
  let cloudTwoRef = useRef(null);
  let birdsRef = useRef(null);
  let pinSectionRef = useRef(null);

  resizeWidthOnly(function () {
    window.location.reload();
  });

  const photographers = props.frontdata.allPhotographers.slice();
  photographers.sort((a, b) => (a.order > b.order ? 1 : -1));

  let viewportWidth = window.innerWidth;

  const [scrollMagic, setScrollMagic] = useState({
    controller: new ScrollMagic.Controller({
      globalSceneOptions: { triggerHook: 'onLeave' },
    }),
    timelineOne: gsap.timeline(),
    timelineTwo: gsap.timeline(),
    timelineThree: gsap.timeline(),
    timelineIcons: gsap.timeline(),
  });
  const {
    controller,
    timelineOne,
    timelineTwo,
    timelineThree,
    timelineIcons,
  } = scrollMagic;

  useEffect(() => {
    timelineOne.to(animationRef.current, {
      height: '460px',
      scrollTrigger: {
        trigger: pinSectionRef.current,
        start: 'top top',
        end: '+=100%',
        scrub: true,
        pin: true,
      },
    });
    timelineOne.to(textGradientRef.current, { opacity: 0 });

    timelineIcons.to(birdsRef.current, {
      top: '10px',
      right: '600px',
      opacity: 1,
      scale: 0.5,
    });

    timelineIcons.to(cloudTwoRef.current, {
      top: '100px',
      left: '10%',
      position: 'absolute',
      height: '100px',
    });

    timelineIcons.to(cloudOneRef.current, {
      position: 'absolute',
      top: '40%',
      right: '-111px',
      opacity: 1,
      height: '117px',
    });

    if (viewportWidth > 1600) {
      timelineTwo.to('#MyPathRoadWrapper', {
        scale: 0.7,
        rotate: '7.5deg',
        right: '150px',
      });

      timelineThree.to('#MyPathRoadWrapper', {
        scale: 1,
        rotate: '7.5deg',
        right: '200px',
      });
    } else if (viewportWidth <= 1600 && viewportWidth > 1250) {
      timelineTwo.to('#MyPathRoadWrapper', {
        scale: 0.7,
        rotate: '7.5deg',
        right: '120px',
      });

      timelineThree.to('#MyPathRoadWrapper', {
        scale: 1,
        rotate: '7.5deg',
        right: '120px',
      });
    } else if (viewportWidth <= 1250 && viewportWidth > 1100) {
      timelineTwo.to('#MyPathRoadWrapper', {
        scale: 0.7,
        rotate: '7.5deg',
        right: '50px',
      });

      timelineThree.to('#MyPathRoadWrapper', {
        scale: 0.98,
        rotate: '7.5deg',
        right: '55px',
      });
    } else if (viewportWidth <= 1100 && viewportWidth > 950) {
      timelineTwo.to('#MyPathRoadWrapper', {
        scale: 0.7,
        rotate: '7.5deg',
        right: '0px',
        top: '20%',
      });

      timelineThree.to('#MyPathRoadWrapper', {
        scale: 0.8,
        rotate: '7.5deg',
        right: '40px',
        top: '10%',
      });
    } else if (viewportWidth <= 950 && viewportWidth > 820) {
      timelineTwo.to('#MyPathRoadWrapper', {
        scale: 0.5,
        rotate: '7.5deg',
        top: '20%',
      });

      timelineThree.to('#MyPathRoadWrapper', {
        scale: 0.6,
        rotate: '7.5deg',
        top: '25%',
      });
    } else if (viewportWidth <= 820 && viewportWidth > 710) {
      timelineTwo.to('#MyPathRoadWrapper', {
        scale: 0.5,
        rotate: '7.5deg',
        right: '95px',
        top: '45%',
      });

      timelineThree.to('#MyPathRoadWrapper', {
        scale: 0.6,
        rotate: '7.5deg',
        right: '75px',
        top: '55%',
      });
    } else if (viewportWidth <= 710 && viewportWidth > 600) {
      timelineTwo.to('#MyPathRoadWrapper', {
        scale: 0.8,
        rotate: '0deg',
        right: '210px',
        top: '45%',
      });

      timelineThree.to('#MyPathRoadWrapper', {
        scale: 1,
        rotate: '0deg',
        right: '200px',
        top: '55%',
      });
    } else if (viewportWidth <= 600 && viewportWidth > 540) {
      timelineTwo.to('#MyPathRoadWrapper', {
        scale: 0.7,
        rotate: '0deg',
        right: '180px',
        top: '40%',
      });

      timelineThree.to('#MyPathRoadWrapper', {
        scale: 1,
        rotate: '0deg',
        right: '170px',
        top: '60%',
      });
    } else if (viewportWidth <= 540 && viewportWidth > 480) {
      timelineTwo.to('#MyPathRoadWrapper', {
        scale: 0.7,
        rotate: '0deg',
        right: '170px',
        top: '50%',
      });

      timelineThree.to('#MyPathRoadWrapper', {
        scale: 0.9,
        rotate: '0deg',
        right: '150px',
        top: '65%',
      });
    } else if (viewportWidth <= 480 && viewportWidth > 420) {
      timelineTwo.to('#MyPathRoadWrapper', {
        scale: 0.7,
        rotate: '0deg',
        right: '130px',
        top: '57%',
      });

      timelineThree.to('#MyPathRoadWrapper', {
        scale: 0.9,
        rotate: '0deg',
        right: '120px',
        top: '62%',
      });
    } else if (viewportWidth <= 420 && viewportWidth > 360) {
      timelineTwo.to('#MyPathRoadWrapper', {
        scale: 0.7,
        rotate: '0deg',
        right: '110px',
        top: '57%',
      });

      timelineThree.to('#MyPathRoadWrapper', {
        scale: 0.8,
        rotate: '0deg',
        right: '100px',
        top: '62%',
      });
    } else if (viewportWidth <= 360) {
      timelineTwo.to('#MyPathRoadWrapper', {
        scale: 0.7,
        rotate: '0deg',
        right: '90px',
        top: '57%',
      });

      timelineThree.to('#MyPathRoadWrapper', {
        scale: 0.8,
        rotate: '0deg',
        right: '80px',
        top: '62%',
      });
    }

    new ScrollMagic.Scene({
      triggerElement: triggerOneRef.current,
      duration: 500,
    })
      .setTween(timelineOne)
      .setTween(timelineTwo)
      .addTo(controller);

    new ScrollMagic.Scene({
      triggerElement: triggerOneRef.current,
      duration: 0,
    })
      .setClassToggle('.bottomtext', 'text_gradient_hide')
      .addTo(controller);

    new ScrollMagic.Scene({
      triggerElement: triggerRef.current,
      duration: 500,
    })
      .setTween(timelineThree)
      .addTo(controller);

    new ScrollMagic.Scene({
      triggerElement: triggerOneRef.current,
      duration: 300,
    })
      .setTween(timelineIcons)
      .addTo(controller);

    new ScrollMagic.Scene({
      triggerElement: triggerOneRef.current,
      duration: 0,
    })
      .setClassToggle(
        '.road-star-and-point_hide',
        'road-star-and-point_visible'
      )
      .addTo(controller);

    new ScrollMagic.Scene({
      triggerElement: triggerOneRef.current,
      duration: 0,
    })
      .setClassToggle('.visible_line', 'hide_line')
      .addTo(controller);

    new ScrollMagic.Scene({
      triggerElement: triggerRef.current,
      duration: 0,
    })
      .setClassToggle('.hide_anim', 'photographers_visible')
      .addTo(controller);
  }, []);

  return (
    <>
      <div
        id="trigger1"
        ref={triggerOneRef}
        style={{
          textAlign: 'center',
          position: 'absolute',
          top: '10px',
          width: '100%',
        }}
      ></div>
      <div
        id="trigger"
        ref={triggerRef}
        style={{
          textAlign: 'center',
          position: 'absolute',
          top: '300px',
          width: '100%',
        }}
      ></div>

      <section id="pin-section" ref={pinSectionRef}>
        <div className="section sec1">
          <div className="wrapperdiv">
            <img className={`Tree_anim3`} src={Trees} alt="" />
            <img className={`misc2_anim`} src={Misc2} alt="" />
            <img src={Trees1} className={`trees1_anim`} />
          </div>
        </div>

        {viewportWidth > 710 ? (
          <RoadPath
            spacing={`spacing`}
            spacing2={`spacing2_anim`}
            shapehide={`shapehide_anim`}
            hide={`hide_anim`}
            allData={photographers}
          />
        ) : (
          <MobileRoadPath
            spacing={`spacing`}
            spacing2={`spacing2_anim`}
            shapehide={`shapehide_anim`}
            hide={`hide_anim`}
            allData={photographers}
          />
        )}

        <div
          style={{
            width: '100%',
            height: '100vh',
            display: 'flex',
            position: 'relative',
            top: '0',
            justifyContent: 'space-between',
          }}
        >
          <div className="text_animation-wrapper">
            <div className="text_animation" ref={animationRef}>
              <p className="welcome-text">Welcome to the Road Trip</p>
              It's the shared moments that make the family. This trip is an open
              door to all sorts of those moments, in all kinds of places and all
              kinds of spaces.{' '}
              <span className="backboxmain">Enjoy the ride.</span>
              <span className="text_gradient" ref={textGradientRef} />
            </div>
          </div>
        </div>

        <img className={`Cloud1_anim3`} src={Clouds} alt="" ref={cloudOneRef} />
        <img className={`Cloud2_anim3`} src={Clouds} alt="" ref={cloudTwoRef} />
        <img className={`Birds_anim3`} src={Birds} alt="" ref={birdsRef} />

        <div className="second-container">
          <img className={`Mountain_anim`} src={TreeSide} alt="" />
          <img className={`Geo1_anim3`} src={GEO1} alt="" />
          <img className={`Anim1_anim`} src={Animal1} alt="" />
          <img className={`geo3_anim`} src={Geo3} alt="" />
          <img className="tiger_bg" src={Tiger} alt="" />
        </div>
      </section>

      {viewportWidth > 710 ? (<div className="home_page-bg">
        <img className={`agave_bg all_img-bg`} src={Agave} alt="" />
        <img className={`aloe_bg all_img-bg`} src={Aloe} alt="" />
        <img className={`bear_bg all_img-bg`} src={Bear} alt="" />
        <img className={`big-birds_bg all_img-bg`} src={BigBirds} alt="" />
        <img className={`butterflies_bg all_img-bg`} src={Butterflies} alt="" />
        <img
          className={`butterflies-two_bg all_img-bg`}
          src={Butterflies}
          alt=""
        />
        <img className={`cacti_bg all_img-bg`} src={Cacti} alt="" />
        <img className={`cat_bg all_img-bg`} src={Cat} alt="" />
        <img className={`corn_bg all_img-bg`} src={Corn} alt="" />
        <img className={`fir-trees_bg all_img-bg`} src={FirTrees} alt="" />
        <img className={`flowers_bg all_img-bg`} src={Flowers} alt="" />
        <img className={`glasses_bg all_img-bg`} src={Glasses} alt="" />
        <img className={`moth_bg all_img-bg`} src={Moth} alt="" />
        <img className={`pond_bg all_img-bg`} src={Pond} alt="" />
        <img className={`skyscrapers_bg all_img-bg`} src={Skyscrapers} alt="" />
        <img className={`wolf_bg all_img-bg`} src={Wolf} alt="" />
        <img className={`deer_bg all_img-bg`} src={Deer} alt="" />
        <img className={`corn-two_bg all_img-bg`} src={Corn} alt="" />
        <img className={`canyon_bg all_img-bg`} src={Canyon} alt="" />
        <img className={`palm-trees_bg all_img-bg`} src={Trees1} />
      </div>) : ''}

      <div className="bottomtext">
        <i>
          It didn't matter how big our house was;
          <br />
          It mattered that there was love in it.
          <br />
        </i>
        <span className="nonItalic">-Peter Buffett</span>
      </div>
    </>
  );
};

export default TweenComponent;
